
$(document).ready(function() {
  "use strict";

  // -----------
  // Mobile menu
  // -----------

  /*$(function() {
      //caches a jQuery object containing the header element
      var header = $(".main-header");
      $(window).scroll(function() {
          var scroll = $(window).scrollTop();

          if (scroll >= 75) {
              header.addClass("sticky");
          } else {
              header.removeClass("sticky");
          }
      });
  });*/

  var handleMatchMedia = function(mediaQuery) {
      // ≤ 760px
      if (mediaQuery.matches) {
        // ocultar el menú
        /*$(".menu-wrapper")
          .attr("hidden", "")
          .addClass("js-hidden");*/
      } else {
        // > 760px
        $("body").removeClass("js-menu-open");
          $(".main-nav button").attr("aria-expanded", "false");
          $(".main-nav button").removeClass("is-active");
        // mostrar el menú
        /*$(".menu-wrapper")
          .removeAttr("hidden")
          .removeClass("js-hidden");*/
      }
    },
    mq = window.matchMedia("only screen and (max-width: 760px)");
  handleMatchMedia(mq);
  mq.addListener(handleMatchMedia);
  // clicar el botó
  $(".main-nav button").on("click", function() {

    // afegir classe al botó
    $(this).toggleClass("is-active");
    // afegir classe al body
    $("body").toggleClass("js-menu-open");
    // canviar atributs del botó i del menú
    if ($(this).attr("aria-expanded") == "true") {
      $(this).attr("aria-expanded", "false");
      /*$(".menu-wrapper")
        .attr("hidden", "")
        .addClass("js-hidden");*/
    } else {
      $(this).attr("aria-expanded", "true");
      /*$(".menu-wrapper")
        .removeAttr("hidden")
        .removeClass("js-hidden");*/
    }
  });

  // --------
  // Submenús
  // --------

  // http://heydonworks.com/practical_aria_examples/#submenus
  $(".main-nav ul div")
    .prev("a")
    .attr("aria-haspopup", "true")
    .append('<span aria-hidden="true"> &#x25be;</span>');

  var showSubmenu = function(dropdown) {
    dropdown.attr("aria-hidden", "false");
  };

  var hideSubmenu = function(dropdown) {
    dropdown.attr("aria-hidden", "true");
  };

  $(".with-dropdowns > li > a").on("focus", function(e) {
    hideSubmenu($('.submenu'));
  });

  $("[aria-haspopup]").on("click", function(e) {
    var submenu = $(this).next();
    showSubmenu(submenu);
    //$(submenu).find('li:first-child a').focus();
    return false;
  });

  $("[aria-haspopup]").hover(function() {
    showSubmenu($(this).next());
    $(this).off("click");
  });

  $("[aria-haspopup]")
    .parents("li")
    .mouseleave(function() {
      hideSubmenu($(this).find('.submenu'));
    });

  // ----------
  // feature.js
  // ----------

  if (feature.svg) {
    $("html").addClass("svg");
  }

  // --------------------------------------------------------------------------------------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // --------------------------------------------------------------------------------------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function(event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function() {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // -----------------
  // Canvi svg per png
  // -----------------

  if (!feature.svg) {
    var imgs = document.getElementsByTagName("img");
    var endsWithDotSvg = /.*\.svg$/;
    var i = 0;
    var l = imgs.length;
    for (; i !== l; ++i) {
      if (imgs[i].src.match(endsWithDotSvg)) {
        imgs[i].src = imgs[i].src.slice(0, -3) + "png";
      }
    }
  }

//home counters

$('.count').each(function () {
    $(this).prop('Counter',0).animate({
        Counter: $(this).text()
    }, {
        duration: 4000,
        easing: 'swing',
        step: function (now) {
            $(this).text(Math.ceil(now));
        }
    });
});



  // ----------------
  // Cercador desktop i contacte
  // ----------------



  // clicar botó
  $('.btn.contacte').on('click', function( e ) {
    e.preventDefault();
    $('.btns .btn.traductor').attr('aria-expanded', 'false');
    $('.btns .traductor--info').attr('aria-hidden', 'true');
    if ($(this).attr('aria-expanded') == 'true') {
      $(this).attr('aria-expanded', 'false');
      $('.contacte--info').attr('aria-hidden', 'true');
    }
    else {
      $(this).attr('aria-expanded', 'true');
      $('.contacte--info').attr('aria-hidden', 'false');
      $('.contacte--info').focus();
    }
    return false;
  });
  $('.btns .close').on('click', function( e ) {
    e.preventDefault();
    $('.btns .btn.contacte').attr('aria-expanded', 'false');
    $('.btns .contacte--info').attr('aria-hidden', 'true');
    $('.btns .btn.traductor').attr('aria-expanded', 'false');
    $('.btns .traductor--info').attr('aria-hidden', 'true');
    return false;
  });

  $('.btn.traductor').on('click', function( e ) {
    e.preventDefault();
    $('.btns .btn.contacte').attr('aria-expanded', 'false');
    $('.btns .contacte--info').attr('aria-hidden', 'true');
    if ($(this).attr('aria-expanded') == 'true') {
      $(this).attr('aria-expanded', 'false');
      $('.traductor--info').attr('aria-hidden', 'true');
    }
    else {
      $(this).attr('aria-expanded', 'true');
      $('.traductor--info').attr('aria-hidden', 'false');
      $('.traductor--info').focus();
    }
    return false;
  });

  $('.traductor-mobile #traductor-mobile--btn').on('click', function( e ) {
    e.preventDefault();
    if ($(this).attr('aria-expanded') == 'true') {
      $(this).attr('aria-expanded', 'false');
      $('.traductor--info-mobile').attr('aria-hidden', 'true');
    }
    else {
      $(this).attr('aria-expanded', 'true');
      $('.traductor--info-mobile').attr('aria-hidden', 'false');

    }
    $('.traductor--info-mobile').focus();
    return false;
  });

  // -----------------
  // accordion
  // -----------------

    $(document).foundation({
    accordion: {
      callback : function (accordion) {
      }
    }
  });


  // -----------------
  // Smooth scroll top
  // -----------------

  if (window.matchMedia('(min-width: 64em)').matches) {
    $(window).scroll(function() {
      if ($(this).scrollTop() > 400) {
          $(".top").fadeIn();
      } else {
          $(".top").fadeOut();
      }
    });
    // var headerHeight = 82; // fixed header height
    $(function() {
      $('a[name]').addClass('offset');
      $('a[href*="#"]:not([href="#"],[href="#menu"],[href^="#panel"])').click(function(e) {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
              e.preventDefault();
              history.pushState({}, "", this.href);
              // if (window.matchMedia("(min-width: 64.0625em)").matches) {
              //       $('html, body').animate({
              //         scrollTop: target.offset().top - headerHeight
              //       }, 1000);
              // } else {
              //       $('html, body').animate({
              //         scrollTop: target.offset().top
              //       }, 1000);
              // }
              $('html, body').animate({
                scrollTop: target.offset().top
              }, 1000);
            return false;
          }
        }
      });
    });
  }

    //Form donació
    $("input:radio[name=344], input:radio[name=329]").on('click', function() {
    var value = $(this).val();
    if (value == 'Un altre import') {
       $("#quantitat-altra-aportacio").removeClass('disabled');
       $("#quantitat-altra-aportacio").removeAttr('disabled');
       $("#altra-aportacio").show();
       $("#quantitat-altra-aportacio").focus();
    }else{
      $("#quantitat-altra-aportacio").addClass('disabled');
        $("#quantitat-altra-aportacio").attr('disabled','');
        $("#altra-aportacio").hide();
    }

});
    $("input.selectorTipus").on('click', function() {
      console.log($(this).val());
      if($(this).val() == 'particular'){
        $('.empresa').removeClass('visible');
        $('.particular').addClass('visible');
      }
      if($(this).val() == 'empresa'){
        $('.empresa').addClass('visible');
        $('.particular').removeClass('visible');
      }
    });

    $('#redsys_form').submit();




  // -------------
  // Sticky header
  // -------------

   var handleMatchMedia2 = function(mediaQuery) {
       // ≥ 1024px
       if (mediaQuery.matches) {
         var lastScrollTop = 139;
         $(window).scroll(function(event) {
           var st = $(this).scrollTop();
           if (st > lastScrollTop) {
             $(".main-nav").addClass("sticky");
           } else {
             $(".main-nav").removeClass("sticky");
           }
           // lastScrollTop = st;
         });

       }
     },
     mq2 = window.matchMedia("only screen and (min-width: 1024px)");
  handleMatchMedia2(mq2);
  mq2.addListener(handleMatchMedia2);

  // -------
  // Galeria
  // -------
var initPhotoSwipeFromDOM = function(gallerySelector) {

    // parse slide data (url, title, size ...) from DOM elements
    // (children of gallerySelector)
    var parseThumbnailElements = function(el) {
        var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;

        for(var i = 0; i < numNodes; i++) {

            figureEl = thumbElements[i]; // <figure> element

            // include only element nodes
            if(figureEl.nodeType !== 1) {
                continue;
            }

            linkEl = figureEl.children[0]; // <a> element

            size = linkEl.getAttribute('data-size').split('x');

            // create slide object
            item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
            };



            if(figureEl.children.length > 1) {
                // <figcaption> content
                item.title = figureEl.children[1].innerHTML;
            }

            if(linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                //item.msrc = linkEl.children[0].getAttribute('src');
            }

            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
        }

        return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
        return el && ( fn(el) ? el : closest(el.parentNode, fn) );
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function(e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function(el) {
            return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
        });

        if(!clickedListItem) {
            return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
            if(childNodes[i].nodeType !== 1) {
                continue;
            }

            if(childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
            }
            nodeIndex++;
        }



        if(index >= 0) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe( index, clickedGallery );
        }
        return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function() {
        var hash = window.location.hash.substring(1),
        params = {};

        if(hash.length < 5) {
            return params;
        }

        var vars = hash.split('&');
        for (var i = 0; i < vars.length; i++) {
            if(!vars[i]) {
                continue;
            }
            var pair = vars[i].split('=');
            if(pair.length < 2) {
                continue;
            }
            params[pair[0]] = pair[1];
        }

        if(params.gid) {
            params.gid = parseInt(params.gid, 10);
        }

        return params;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
            items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {

            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),
            showHideOpacity: true,
            getThumbBoundsFn: function(index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect();

                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
            }

        };

        // PhotoSwipe opened from URL
        if(fromURL) {
            if(options.galleryPIDs) {
                // parse real index when custom PIDs are used
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for(var j = 0; j < items.length; j++) {
                    if(items[j].pid == index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if( isNaN(options.index) ) {
            return;
        }

        if(disableAnimation) {
            options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    };

    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll( gallerySelector );

    for(var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i+1);
        galleryElements[i].onclick = onThumbnailsClick;
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if(hashData.pid && hashData.gid) {
        openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
    }
};

// execute above function
initPhotoSwipeFromDOM('.my-gallery');

  //galeria
  $('.popup-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Loading image #%curr%...',
    mainClass: 'mfp-img-mobile',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0,1], // Will preload 0 - before current, and 1 after the current image
      tCounter: '%curr% de %total%'
    },
    image: {
      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
      titleSrc: function(item) {
        return item.el.attr('title');
      }
    }
  });


  // ------------
  // Effect
  // ------------
  $.fn.isInViewport = function() {
    var elementTop = $(this).offset().top + 40;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };
  $(window).on('resize scroll load', function() {
    $('.enter-effect').each(function() {
      if ($(this).isInViewport()) {
        $(this).parent().addClass('anim');
      }
    });
  });


  // ------------
  // Match height
  // ------------

  $(".ac").matchHeight();
  $(".box").matchHeight();
  $(".boxico").matchHeight();
  $(".box-news").matchHeight();
  $(".box-agenda").matchHeight();
  $(".box-docs li div").matchHeight();
  $(".ofertes li .box-of").matchHeight();
  $(".not-col").matchHeight();
  $(".org-chart").matchHeight();
  $(".llista_cards li").matchHeight();


  // --------------
  // Toggle idiomes
  // --------------

  $(".lang-toggle").click(function(event) {
    event.stopPropagation();
    $(this).toggleClass("close");
    $(this)
      .next()
      .toggleClass("is-hidden");
  });
  $(document).click(function() {
    $(".lang-toggle")
      .next()
      .addClass("is-hidden");
  });

  // -------
  // Sliders
  // -------

  $(".slider").slick({
    //dots: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 12000,
    adaptiveHeight: true,
    cssEase: 'linear',
    nextArrow: "<img class='next slick-next' src='/media/img/ico-next.svg'>",
    prevArrow: "<img class='prev slick-prev' src='/media/img/ico-back.svg'>"
  });

  $(".slider-equip").slick({
    autoplay: true,
    arrows: true,
    autoplaySpeed: 12000,
    adaptiveHeight: true,
    cssEase: 'linear',
    nextArrow: "<img class='next slick-next' src='/media/img/ico-next.svg'>",
    prevArrow: "<img class='prev slick-prev' src='/media/img/ico-back.svg'>"
  });



  /* 4. Tab Interface
  -----------------------------------------------------------------------------------------
  */

  // The class for the container div

  var $container = '.tab-interface';

  // The setup

  $($container +' ul.seccions').attr('role','tablist');
  $($container +' [role="tablist"] li').attr('role','presentation');
  $('[role="tablist"] a').attr({
      'role' : 'tab',
      'tabindex' : '-1'
  });

  // Make each aria-controls correspond id of targeted section (re href)

  $('[role="tablist"] a').each(function() {
    $(this).attr(
      'aria-controls', $(this).attr('href').substring(1)
    );
  });



  // Make each section focusable and give it the tabpanel role

  $($container +' section').attr({
    'role' : 'tabpanel'
  });

  // Make first child of each panel focusable programmatically

  $($container +' section .text > *:first-child').attr({
      'tabindex' : '0'
  });

  var handleMatchMedia2 = function(mq) {
    if (mq.matches) {
      $('[role="tabpanel"]').attr({
        'aria-hidden' : 'true'
      });
      $('[role="tab"]').attr({
      'tabindex': '-1',
      'aria-selected' : null
      });
    } else {
      // Make the first tab selected by default and allow it focus
      $('[role="tablist"] li:first-child a').attr({
          'aria-selected' : 'true',
          'tabindex' : '0'
      });
      // Make all but the first section hidden (ARIA state and display CSS)
      $('[role="tabpanel"]:first-of-type').attr({
        'aria-hidden' : null
      });
      $('[role="tabpanel"]:not(:first-of-type)').attr({
        'aria-hidden' : 'true'
      });
    }
  }
  handleMatchMedia2(mq);



  // Change focus between tabs with arrow keys

  $('[role="tab"]').on('keydown', function(e) {

    // define current, previous and next (possible) tabs

    var $original = $(this);
    var $prev = $(this).parents('li').prev().children('[role="tab"]');
    var $next = $(this).parents('li').next().children('[role="tab"]');
    var $target;

    // find the direction (prev or next)

    switch (e.keyCode) {
      case 37:
        $target = $prev;
        break;
      case 39:
        $target = $next;
        break;
      default:
        $target = false
        break;
    }

    if ($target.length) {
        $original.attr({
          'tabindex' : '-1',
          'aria-selected' : null
        });
        $target.attr({
          'tabindex' : '0',
          'aria-selected' : true
        }).focus();
    }

    // Hide panels

    $($container +' [role="tabpanel"]')
      .attr('aria-hidden', 'true');

    // Show panel which corresponds to target

    $('#' + $(document.activeElement).attr('href').substring(1))
      .attr('aria-hidden', null);

  });

  // Handle click on tab to show + focus tabpanel

  $('[role="tab"]').on('click', function(e) {

    e.preventDefault();

    // remove focusability [sic] and aria-selected

    $('[role="tab"]').attr({
      'tabindex': '-1',
      'aria-selected' : null
      });

    // replace above on clicked tab

    $(this).attr({
      'aria-selected' : true,
      'tabindex' : '0'
    });

    // Hide panels

    $($container +' [role="tabpanel"]').attr('aria-hidden', 'true');

    // show corresponding panel

    $('#' + $(this).attr('href').substring(1))
      .attr('aria-hidden', null);
      if (mq.matches) {
        $('html, body').animate({
            scrollTop: $('#' + $(this).attr('href').substring(1)).offset().top - 300
        }, 300);
      }


  });
  $($container).addClass('init');

  /* 3. Progressive collapsibles
  -----------------------------------------------------------------------------------------
  */

  $('.collapsible .titleac').each(function() {

    var $this = $(this);
    $('.collapsible').addClass('init');
    // create unique id for a11y relationship

    var id = 'collapsible-' + $this.index();

    // wrap the content and make it focusable

    $this.nextUntil('h2').wrapAll('<div id="'+ id +'" aria-hidden="true">');
    var panel = $this.next();

    // Add the button inside the <h2> so both the heading and button semanics are read

    $this.wrapInner('<button aria-expanded="false" aria-controls="'+ id +'">');
    var button = $this.children('button');

    // Toggle the state properties

    button.on('click', function() {
      var state = $(this).attr('aria-expanded') === 'false' ? true : false;
      $(this).attr('aria-expanded', state);
      panel.attr('aria-hidden', !state);
    });

  });



});




